import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import Form from "react-bootstrap/Form";
import { Store } from "../Store";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import LoadingBox from "../components/LoadingBox";
import { toast } from "react-toastify";
import { getError, makeApiRequest } from "../utils";
import axios from "axios";
import MessageBox from "../components/MessageBox";
import { useNavigate, useParams } from "react-router-dom";
const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return { ...state, loadingCreate: false };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false, error: action.payload };
    default:
      return state;
  }
};

function CreateSize() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: sizeId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingCreate }, dispatch] = useReducer(reducer, {
    loadingCreate: false,
    error: "",
  });
  const [name, setName] = useState("");
  const [human_name, setHumanName] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "CREATE_REQUEST" });
    try {
      const { data } = await makeApiRequest(
        "/size",
        "post",
        {
          name,
          human_name,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      console.log(data);
      dispatch({ type: "CREATE_SUCCESS" });
      toast.success("Color created successfully");
      navigate("/admin/sizes");
    } catch (err) {
      dispatch({
        type: "CREATE_FAIL",
        payload: getError(err),
      });
    }
  };

  return (
    <Container className="small-container my-3">
      <Helmet>
        <title>New Size ${`${sizeId}`}</title>
      </Helmet>
      <h1>New Size {sizeId}</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control onChange={(e) => setName(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="order">
            <Form.Label>Human Nmae </Form.Label>
            <Form.Control
              onChange={(e) => setHumanName(e.target.value)}
              required
            />
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingCreate} type="submit">
              Add Size
            </Button>
            {loadingCreate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}

export default CreateSize;
