import React, { useContext, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import Form from "react-bootstrap/Form";
import { Store } from "../Store";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import LoadingBox from "../components/LoadingBox";
import { toast } from "react-toastify";
import { getError, makeApiRequest } from "../utils";
import axios from "axios";
import MessageBox from "../components/MessageBox";
import { useNavigate, useParams } from "react-router-dom";
const reducer = (state, action) => {
  switch (action.type) {
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return { ...state, loadingCreate: false };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false, error: action.payload };
    default:
      return state;
  }
};

function CreateBrand() {
  const navigate = useNavigate();
  const params = useParams(); // /brand/:id
  const { id: brandId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, loadingCreate, error }, dispatch] = useReducer(reducer, {
    loading: false,
    error: "",
  });

  const [name, setName] = useState("");
  const [order, setOrder] = useState("");
  const [image, setImage] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "CREATE_REQUEST" });
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("order", order);
      formData.append("brand_image", image);
      await makeApiRequest("/brand", "post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      dispatch({ type: "CREATE_SUCCESS" });

      toast.success("Brand created successfully");
      navigate("/admin/brand");
    } catch (err) {
      toast.error(getError(err)[0]);
      dispatch({
        type: "CREATE_FAIL",
        payload: getError(err),
      });
    }
  };

  return (
    <Container className="small-container my-3">
      <Helmet>
        <title>New Brand ${`${brandId}`}</title>
      </Helmet>
      <h1>New Brand {brandId}</h1>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control onChange={(e) => setName(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="order">
            <Form.Label>Order </Form.Label>
            <Form.Control onChange={(e) => setOrder(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="image">
            <Form.Label>Brand Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingCreate} type="submit">
              Add Brand
            </Button>
            {loadingCreate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}

export default CreateBrand;
