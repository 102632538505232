//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

const SideBar = () => {
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <Sidebar className="h-100" variant="dark" collapsed={menuCollapse}>
      <Menu iconShape="square">
        {/* <MenuItem routerLink={<Link to='/' />} icon={<i class="fa fa-fw fa-home"/>}>Home</MenuItem> */}
        <MenuItem
          className="mt-2"
          routerLink={<Link to="/" />}
          icon={<i class="fa fa-fw fa-dashboard" />}
        >
          Dashboard
        </MenuItem>
        <SubMenu icon={<i class="fa fa-fw fa-shop" />} label="Products">
          <MenuItem
            routerLink={<Link to="/admin/product" />}
            className="text-dark"
          >
            all{" "}
          </MenuItem>
          <MenuItem
            className="text-dark"
            routerLink={<Link to="/admin/product/new" />}
          >
            <i className="fas fa-plus "></i> Create{" "}
          </MenuItem>
        </SubMenu>
        <SubMenu icon={<i className="fa fa-product-hunt" />} label="Category">
          <MenuItem
            routerLink={<Link to="/admin/category" />}
            className="text-dark"
          >
            all{" "}
          </MenuItem>
          <MenuItem
            className="text-dark"
            routerLink={<Link to="/admin/category/new" />}
          >
            <i class="fas fa-plus "></i> Create{" "}
          </MenuItem>
        </SubMenu>
        <SubMenu icon={<i className="fa fa-product-hunt" />} label="Brand">
          <MenuItem
            routerLink={<Link to="/admin/brand" />}
            className="text-dark"
          >
            all{" "}
          </MenuItem>
          <MenuItem
            className="text-dark"
            routerLink={<Link to="/admin/brand/new" />}
          >
            <i class="fas fa-plus "></i> Create{" "}
          </MenuItem>
        </SubMenu>
        <SubMenu icon={<i className="fas fa-palette" />} label="Colors">
          <MenuItem
            routerLink={<Link to="/admin/colors" />}
            className="text-dark"
          >
            all{" "}
          </MenuItem>
          <MenuItem
            className="text-dark"
            routerLink={<Link to="/admin/color/new" />}
          >
            <i class="fas fa-plus "></i> Create{" "}
          </MenuItem>
        </SubMenu>
        <SubMenu icon={<i className="fas fa-crop" />} label="Sizes">
          <MenuItem
            routerLink={<Link to="/admin/sizes" />}
            className="text-dark"
          >
            All{" "}
          </MenuItem>
          <MenuItem
            className="text-dark"
            routerLink={<Link to="/admin/size/new" />}
          >
            <i class="fas fa-plus "></i> Create{" "}
          </MenuItem>
        </SubMenu>
        <MenuItem
          routerLink={<Link to="/admin/orders" />}
          icon={<i className="fas fa-shopping-cart" />}
        >
          Orders
        </MenuItem>
        <MenuItem
          routerLink={<Link to="/admin/users" />}
          icon={<i className="fas fa-users" />}
        >
          Users
        </MenuItem>
        <MenuItem
          routerLink={<Link to="/admin/reviews" />}
          icon={<i className="fas fa-grin-stars" />}
        >
          Reviews
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
