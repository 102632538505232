import React, { useContext, useEffect, useReducer } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";
import axios from "axios";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError, makeApiRequest } from "../utils";
import { toast } from "react-toastify";
import SideBar from "../components/SideBar";
import { Container, Table } from "react-bootstrap";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, orders: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false };
    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

export default function OrderListScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [{ loading, error, orders, loadingDelete, successDelete }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });
  useEffect(() => {
    const fetchOrdersList = async () => {
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const { data } = await makeApiRequest("/order/all", "get", null, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: "FETCH_SUCCESS", payload: data.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchOrdersList();
    }
  }, [userInfo, successDelete]);
  const deleteHandler = async (order) => {
    if (window.confirm("Are YOU Sure to delete?")) {
      try {
        dispatch({ type: "DELETE_REQUEST" });
        const delorder = await makeApiRequest(
          `/order/cancel_order/${order._id}`,
          "delete",
          null,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        console.log(delorder);
        toast.success("Order deleted successfully");
        dispatch({ type: "DELETE_SUCCESS" });
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: "DELETE_FAIL" });
      }
    }
  };
  return (
    <Row className="minHieght">
      <Col xs={12} sm={12} md={4} lg={3} xl={3}>
        <SideBar />
      </Col>
      <Col xs={12} sm={12} md={8} lg={9} xl={9}>
        {/* Content for the second column */}
        <div className="px-3">
          <Helmet>
            <title>Order List Screen</title>
          </Helmet>
          <h1 className="my-4">Order List Screen</h1>
          {loadingDelete && <LoadingBox></LoadingBox>}
          {loading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>USER</th>
                  <th>DATE</th>
                  <th>TOTAL</th>
                  <th>SUB</th>
                  <th>ADDRESS</th>
                  <th>STATUS</th>
                  <th>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.user}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.total?.total?.toFixed(2)}</td>
                    <td>{order.total?.sub_total?.toFixed(2)}</td>
                    <td>{order.address?.address}</td>
                    <td>{order.status_name}</td>
                    <td className="d-flex">
                      <Button
                        type="button"
                        variant="primary"
                        onClick={() => navigate(`/order/${order._id}`)}
                      >
                        Detais
                      </Button>
                      &nbsp;
                      <Button
                        type="button"
                        variant="danger"
                        onClick={() => deleteHandler(order)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
}
