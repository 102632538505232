import React, { useContext, useEffect, useReducer } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Store } from "../Store";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError, makeApiRequest } from "../utils";
import SideBar from "../components/SideBar";
import { Table } from "react-bootstrap";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };

    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

export default function ProductListScreen() {
  const [
    {
      loading,
      error,
      products,
      pages,
      loadingCreate,
      loadingDelete,
      successDelete,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  const navigate = useNavigate();
  const { search } = useLocation();

  const sp = new URLSearchParams(search);
  const page = sp.get("page") || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await makeApiRequest(`/product/search`, "get", null, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log(data);
        dispatch({ type: "FETCH_SUCCESS", payload: data });
      } catch (err) {
        console.log(err);
      }
    };

    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      const data = fetchData();
      console.log(data);
    }
  }, [page, userInfo, successDelete]);
  const deleteHandler = async (product) => {
    if (window.confirm("Are you sure to delete?")) {
      try {
        await makeApiRequest(`/product/${product._id}`, "delete", null, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success("product deleted successfully");
        dispatch({ type: "DELETE_SUCCESS" });
      } catch (err) {
        toast.error(getError(err));
        dispatch({
          type: "DELETE_FAIL",
        });
      }
    }
  };

  return (
    <Row className="minHieght">
      <Col sm={12} md={4} lg={4} xl={3}>
        <SideBar />
      </Col>
      <Col sm={12} md={8} lg={8} xl={9}>
        <div>
          <Row>
            <Col>
              <h1 className="mt-4">Products</h1>
            </Col>
            <Col className="col text-end">
              <div className="m-3">
                <Button
                  type="button"
                  onClick={() => navigate("/admin/product/new")}
                >
                  Create Product
                </Button>
              </div>
            </Col>
          </Row>

          {loadingCreate && <LoadingBox></LoadingBox>}
          {loadingDelete && <LoadingBox></LoadingBox>}

          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>TIYLE</th>
                    <th>PRICE</th>
                    <th>IMAGE</th>
                    <th>CATEGORY</th>
                    <th>BRAND</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product._id}</td>
                      <td>{product.title}</td>
                      <td>{product.price}</td>
                      <td>
                        <img
                          src={product.image_url}
                          alt="product"
                          style={{ width: "5rem", height: "auto" }}
                        />
                      </td>
                      <td>{product.category_id?.name}</td>
                      <td>{product.brand_id?.name}</td>
                      <td className="d-flex">
                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => navigate(`/product/${product._id}`)}
                        >
                          Edit
                        </Button>
                        &nbsp;
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => deleteHandler(product)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <div>
                {[...Array(pages).keys()].map((x) => (
                  <Link
                    className={x + 1 === Number(page) ? "btn text-bold" : "btn"}
                    key={x + 1}
                    to={`/admin/products?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div> */}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
}
