import React, { useContext, useEffect, useReducer } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Store } from "../Store";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import { getError, makeApiRequest } from "../utils";
import SideBar from "../components/SideBar";
import { Table } from "react-bootstrap";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return {
        ...state,
        sizes: action.payload,
        page: action.payload.page,
        pages: action.payload.pages,
        loading: false,
      };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "CREATE_REQUEST":
      return { ...state, loadingCreate: true };
    case "CREATE_SUCCESS":
      return {
        ...state,
        loadingCreate: false,
      };
    case "CREATE_FAIL":
      return { ...state, loadingCreate: false };

    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false, successDelete: false };

    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};

export default function SizeListScreen() {
  const [
    {
      loading,
      error,
      sizes,
      pages,
      loadingCreate,
      loadingDelete,
      successDelete,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  const navigate = useNavigate();
  const { search } = useLocation();

  const sp = new URLSearchParams(search);
  const page = sp.get("page") || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await makeApiRequest(`/size`, "get", null, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: "FETCH_SUCCESS", payload: data.sizes });
      } catch (err) {}
    };

    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      fetchData();
    }
  }, [page, userInfo, successDelete]);

  const deleteHandler = async (size) => {
    if (window.confirm("Are you sure to delete?")) {
      try {
        const res = await makeApiRequest(`/size/${size._id}`, "delete", null, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log(res);
        toast.success("Size deleted successfully");

        dispatch({ type: "DELETE_SUCCESS" });
      } catch (err) {
        toast.error(getError(error));
        dispatch({
          type: "DELETE_FAIL",
        });
      }
    }
  };

  return (
    <Row className="minHieght">
      <Col
        sm={12}
        md={4}
        lg={3}
        xl={3}
        className="d-flex flex-column minHieght"
      >
        <SideBar />
      </Col>
      <Col sm={12} md={8} lg={9} xl={9}>
        <div className="p-3">
          <Row>
            <Col>
              <h1>Sizes</h1>
            </Col>
            <Col className="col text-end">
              <div className="m-3">
                <Button
                  type="button"
                  onClick={() => navigate("/admin/size/new")}
                >
                  Create Size
                </Button>
              </div>
            </Col>
          </Row>

          {loadingCreate && <LoadingBox></LoadingBox>}
          {loadingDelete && <LoadingBox></LoadingBox>}

          {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>HUMAN NAME</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {sizes.map((size) => (
                    <tr key={size._id}>
                      <td>{size._id}</td>
                      <td>{size.name}</td>
                      <td>{size.human_name}</td>
                      <td>
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => deleteHandler(size)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* <div>
                {[...Array(pages).keys()].map((x) => (
                  <Link
                    className={x + 1 === Number(page) ? "btn text-bold" : "btn"}
                    key={x + 1}
                    to={`/admin/category?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div> */}
            </>
          )}
        </div>
      </Col>
    </Row>
  );
}
