import "./App.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LinkContainer } from "react-router-bootstrap";
import { Store } from "./Store";
import { useContext, useEffect, useState } from "react";
import SigninScreen from "./Screens/SigninScreen";
import SignupScreen from "./Screens/SignupScreen";
import PrivacyScreenn from "./Screens/PrivacyScreen";
import OrderScreen from "./Screens/OrderScreen";
import ProfileScreen from "./Screens/ProfileScreen";
import Footer from "./components/Footer";
import { getError } from "./utils";
import axios from "axios";

import DashboardScreen from "./Screens/DashboardScreen";
import AdminRoute from "./components/AdminRoute";
// import ProtectedRoute from "./components/ProtectedRoute";
import OrderListScreen from "./Screens/OrderListScreen";
import CreateProduct from "./Screens/CreateProduct";
import ProductListScreen from "./Screens/ProductListScreen";
import ProductEditScreen from "./Screens/ProductEditScreen";

import UsersListScreen from "./Screens/UsersListScreen";
import CreateCategory from "./Screens/CreateCategory";
import BrandListScreen from "./Screens/BrandListScreen";
import CreateBrandScreen from "./Screens/CreateBrand";
import CategoryListScreen from "./Screens/CategoryListScreen";
import ReviewsListScreen from "./Screens/ReviewListScreen";
import ColorListScreen from "./Screens/ColorListScreen";
import SizeListScreen from "./Screens/SizeListScreen";
import CreateColorScreen from "./Screens/CreateColor";
import CreateSize from "./Screens/CreateSize";
function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const signoutHandler = () => {
    ctxDispatch({ type: "USER_SIGNOUT" });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("shippingAddress");
    localStorage.removeItem("paymentMethod");
    window.location.href("/signin");
  };

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const { data } = await axios.get(`/category`);
  //       setCategories(data);
  //     } catch (err) {
  //       toast.error(getError(err));
  //     }
  //   };
  //   fetchCategories();
  // }, []);
  return (
    <BrowserRouter>
      <div
        className={
          sidebarIsOpen
            ? "d-flex flex-column site-container active-cont"
            : "d-flex flex-column site-container"
        }
      >
        <ToastContainer position="top-center" limit={1} />
        <header>
          <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
              <LinkContainer to="/">
                <LinkContainer to="/">
                  <Navbar.Brand>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icon.webp"}
                      width={40}
                      height={40}
                      alt="ag atelier icon"
                      className="me-3"
                    />
                    Ag Atelier
                  </Navbar.Brand>
                </LinkContainer>
              </LinkContainer>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {/* <SearchBox /> */}
                <Nav className="me-auto w-100 justify-content-end">
                  {!userInfo && (
                    <Link className="nav-link" to="/signin">
                      Sign in{" "}
                    </Link>
                  )}
                  {userInfo && (
                    <NavDropdown
                      title={userInfo.first_name}
                      id="admin-nav-dropdown"
                    >
                      <LinkContainer to="/">
                        <NavDropdown.Item>Dashboard</NavDropdown.Item>
                      </LinkContainer>
                      {/* <LinkContainer to="/profile">
                        <NavDropdown.Item>Profile</NavDropdown.Item>
                      </LinkContainer> */}
                      <Link
                        className="dropdown-item"
                        to="/#signout"
                        onClick={signoutHandler}
                      >
                        Sign Out
                      </Link>
                    </NavDropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <div
          className={
            sidebarIsOpen
              ? "active-nav side-navbar d-flex justify-content-between flex-wrap flex-column h-100"
              : "side-navbar d-flex justify-content-between flex-wrap flex-column"
          }
        ></div>
        <main>
          {/* className={path ==='/admin/dashboard' ?'m-0 p-0': ''}  */}
          <Routes>
            <Route path="/signin" element={<SigninScreen />} />
            <Route path="/signup" element={<SignupScreen />} />
            <Route path="/privacy-policy" element={<PrivacyScreenn />} />
            {/* <Route
              path="/profile"
              element={
                <AdminRoute>
                  <ProfileScreen />
                </AdminRoute>
              }
            /> */}

            <Route path="/order/:id" element={<OrderScreen />} />
            {/*addmin routes */}
            <Route
              path="/"
              exact
              element={
                <AdminRoute>
                  <DashboardScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/orders"
              element={
                <AdminRoute>
                  <OrderListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/product"
              element={
                <AdminRoute>
                  <ProductListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/product/new"
              element={
                <AdminRoute>
                  <CreateProduct />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/category"
              element={
                <AdminRoute>
                  <CategoryListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/category/new"
              element={
                <AdminRoute>
                  <CreateCategory />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/brand"
              element={
                <AdminRoute>
                  <BrandListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/brand/new"
              element={
                <AdminRoute>
                  <CreateBrandScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/product/:id"
              element={
                <AdminRoute>
                  <ProductEditScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AdminRoute>
                  <UsersListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/reviews"
              element={
                <AdminRoute>
                  <ReviewsListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/colors"
              element={
                <AdminRoute>
                  <ColorListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/color/new"
              element={
                <AdminRoute>
                  <CreateColorScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/sizes"
              element={
                <AdminRoute>
                  <SizeListScreen />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/size/new"
              element={
                <AdminRoute>
                  <CreateSize />
                </AdminRoute>
              }
            />
          </Routes>
        </main>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
