import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import Form from "react-bootstrap/Form";
import { Store } from "../Store";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import LoadingBox from "../components/LoadingBox";
import { toast } from "react-toastify";
import { getError, makeApiRequest } from "../utils";
import axios from "axios";
import MessageBox from "../components/MessageBox";
import { useNavigate, useParams } from "react-router-dom";
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    case "UPLOAD_REQUEST":
      return { ...state, loadingUpload: true, errorUpload: "" };
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        loadingUpload: false,
        errorUpload: "",
      };
    case "UPLOAD_FAIL":
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};
function CreateProduct() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: productId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });

  const [title, setTitle] = useState("");
  const [fabric_type, setFabricType] = useState("");
  const [price, setPrice] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [is_offer, setIsOffer] = useState(0);
  const [is_new, setIsNew] = useState(0);
  const [is_featured, setIsFeatured] = useState(0);
  const [offer_type, setOfferType] = useState("");
  const [offer_price, setOfferPrice] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        // Fetch category options
        const { data: categoryData } = await makeApiRequest("/category", "get");
        setCategoryOptions(categoryData.category);
        console.log(categoryData.category);
        // Fetch brand options
        const { data: brandData } = await makeApiRequest("/brand", "get");
        setBrandOptions(brandData.brand);
        console.log(brandData);
        // Fetch color options
        const { data: colorData } = await makeApiRequest("/color", "get");
        setColorOptions(colorData.colors);
        console.log(colorData);
        // Fetch size options
        const { data: sizeData } = await makeApiRequest("/size", "get");
        setSizeOptions(sizeData.sizes);
        console.log(sizeData);
        dispatch({ type: "FETCH_SUCCESS" });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "UPLOAD_REQUEST" });
    try {
      let uploadedimages;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("fabric_type", fabric_type);
      formData.append("price", price);
      formData.append("qrcode", qrcode);
      formData.append("product_image", image);

      for (let i = 0; i < images.length; i++) {
        uploadedimages = images[i];
      }
      formData.append("product_images", uploadedimages);
      formData.append("category_id", category);
      formData.append("brand_id", brand);
      formData.append("colors", [colors]);
      formData.append("sizes", [sizes]);
      formData.append("description", description);
      // Check if Is Offer is selected
      if (is_offer !== "") {
        formData.append("is_offer", is_offer);
      }
      // Check if Is Offer is selected
      if (is_new !== "") {
        formData.append("is_new", is_new);
      }
      // Check if Offer Type is selected
      if (offer_type !== "") {
        formData.append("offer_type", offer_type);
      }

      // Check if Offer Price is provided
      if (offer_price !== "") {
        formData.append("offer_price", offer_price);
      }
      // Check if Offer Price is provided
      if (is_featured !== "") {
        formData.append("is_featured", is_featured);
      }
      console.log(formData, "form ");
      await makeApiRequest("/product", "post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      dispatch({ type: "UPLOAD_SUCCESS" });
      toast.success("Product created successfully", {
        autoClose: 3000,
        delay: 2000,
      });
      navigate("/admin/product");
    } catch (err) {
      toast.error(getError(err)[0]);
      dispatch({
        type: "UPLOAD_FAIL",
        payload: getError(err),
      });
    }
  };

  // Use a function to handle the change event
  const handleChange = (e, type) => {
    // Get the options from the event target
    const options = e.target.options;
    // Create an empty array to store the selected values
    const selectedValues = [];
    // Loop through the options and push the selected ones to the array
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    // Update the state with the array of selected values
    if (type === "color") {
      setColors(selectedValues);
    } else {
      // Update the state with the array of selected values
      setSizes(selectedValues);
    }
  };
  return (
    <Container className="small-container my-3">
      <Helmet>
        <title>New Product ${`${productId}`}</title>
      </Helmet>
      <h1>New Product {productId}</h1>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control onChange={(e) => setTitle(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="fabric_type">
            <Form.Label>Fabric Type</Form.Label>
            <Form.Control
              onChange={(e) => setFabricType(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrcode">
            <Form.Label>Qrcode</Form.Label>
            <Form.Control
              onChange={(e) => setQrcode(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="image">
            <Form.Label>Image File</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="images">
            <Form.Label>Additional Images</Form.Label>
            <Form.Control
              type="file"
              multiple
              onChange={(e) => setImages(e.target.files)}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select category...</option>
              {categoryOptions.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="brand">
            <Form.Label>Brand</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setBrand(e.target.value)}
              required
            >
              <option value="">Select brand...</option>
              {brandOptions.map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="color">
            <Form.Label>Color</Form.Label>
            <Form.Control
              as="select"
              multiple // Add this attribute
              value={colors} // Pass the array of selected values
              onChange={(e) => handleChange(e, "color")} // Use the function to handle the change event
              required
            >
              <option value="">Select color...</option>
              {colorOptions.map((color) => (
                <option key={color._id} value={color._id}>
                  {color.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="size">
            <Form.Label>Size</Form.Label>
            <Form.Control
              as="select"
              multiple // Add this attribute
              value={sizes} // Pass the array of selected values
              onChange={(e) => handleChange(e)}
              required
            >
              <option value="">Select size...</option>
              {sizeOptions.map((size) => (
                <option key={size._id} value={size._id}>
                  {size.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="is_offer">
            <Form.Label>Is Offer</Form.Label>
            <Form.Select onChange={(e) => setIsOffer(e.target.value)}>
              <option value="">Select an option</option>
              <option value="0">False</option>
              <option value="1">True</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="offer_type">
            <Form.Label>Offer Type</Form.Label>
            <Form.Select onChange={(e) => setOfferType(e.target.value)}>
              <option value="">Select an option</option>
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="offer_price">
            <Form.Label>Offer Price</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setOfferPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="is_new">
            <Form.Label>Is New</Form.Label>
            <Form.Select onChange={(e) => setIsNew(e.target.value)}>
              <option value="">Select an option</option>
              <option value="0">False</option>
              <option value="1">True</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="is_new">
            <Form.Label>Is Featured</Form.Label>
            <Form.Select onChange={(e) => setIsFeatured(e.target.value)}>
              <option value="">Select an option</option>
              <option value="0">False</option>
              <option value="1">True</option>
            </Form.Select>
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingUpload} type="submit">
              Add Product
            </Button>
            {loadingUpload && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}

export default CreateProduct;
