import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Store } from "../Store";
import { getError, handleChange, makeApiRequest } from "../utils";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import { Helmet } from "react-helmet-async";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import Button from "react-bootstrap/Button";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    case "UPLOAD_REQUEST":
      return { ...state, loadingUpload: true, errorUpload: "" };
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        loadingUpload: false,
        errorUpload: "",
      };
    case "UPLOAD_FAIL":
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};
export default function ProductEditScreen() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: product_id } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });

  const [title, setTitle] = useState("");
  const [fabric_type, setFabricType] = useState("");
  const [price, setPrice] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [brand, setBrand] = useState("");
  const [description, setDescription] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [colorOptions, setColorOptions] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [is_offer, setIsOffer] = useState(0);
  const [is_new, setIsNew] = useState(0);
  const [is_featured, setIsFeatured] = useState(0);
  const [offer_type, setOfferType] = useState("");
  const [offer_price, setOfferPrice] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await makeApiRequest(
          `/product/show?product_id=${product_id}`,
          "get",
          null,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        console.log(data);
        // Fetch category options
        const { data: categoryData } = await makeApiRequest("/category", "get");
        setCategoryOptions(categoryData.category);
        // Fetch brand options
        const { data: brandData } = await makeApiRequest("/brand", "get");
        setBrandOptions(brandData.brand);

        // Fetch color options
        const { data: colorData } = await makeApiRequest("/color", "get");
        setColorOptions(colorData.colors);

        // Fetch size options
        const { data: sizeData } = await makeApiRequest("/size", "get");
        setSizeOptions(sizeData.sizes);

        let product = data.data.product;
        setTitle(product.title);
        setFabricType(product.fabric_type);
        setPrice(product.price);
        setCategory(product.category_id?._id);
        setQrcode(product.qrcode);
        setIsNew(product.is_new);
        setIsOffer(product.is_offer);
        setOfferPrice(product.offer_price);
        setOfferType(product.offer_type);
        setIsFeatured(product.is_featured);
        setBrand(product.brand_id?._id);
        setDescription(product.description);
        setSizes(product.sizes.map((size) => size?._id));
        setColors(product.colors.map((color) => color?._id));

        dispatch({ type: "FETCH_SUCCESS" });
      } catch (err) {
        toast.error(getError(err)[0]);
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [product_id, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: "UPDATE_REQUEST" });
      let uploadedimages;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("fabric_type", fabric_type);
      formData.append("price", price);
      formData.append("qrcode", qrcode);
      formData.append("category_id", category);
      formData.append("brand_id", brand);
      formData.append("colors", [colors]);
      formData.append("sizes", [sizes]);
      formData.append("description", description);

      if (image instanceof File) {
        // If the user has selected a new image, upload it to the server
        formData.append("product_image", image);
      }
      if (images && images.length > 0) {
        // If the user has selected new images, upload them to the server
        for (let i = 0; i < images.length; i++) {
          uploadedimages = images[i];
        }
        formData.append("product_images", uploadedimages);
      }
      formData.append("is_offer", is_offer);
      formData.append("is_new", is_new);
      if (offer_type !== "") {
        formData.append("offer_type", offer_type);
      }
      if (offer_price > 0) {
        formData.append("offer_price", offer_price);
      }
      formData.append("is_featured", is_featured);
      console.log(formData);
      await makeApiRequest(`/product/${product_id}`, "post", formData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({
        type: "UPDATE_SUCCESS",
      });
      toast.success("Product updated successfully");
      navigate("/admin/product");
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: "UPDATE_FAIL" });
    }
  };

  return (
    <Container className="small-container">
      <Helmet>
        <title>Edit Product ${`${product_id}`}</title>
      </Helmet>
      <h1>Edit Product ${`${product_id}`}</h1>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <p className="alert alert-info">
            All fields are optional. You can edit or leave them out
          </p>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="fabric_type">
            <Form.Label>Fabric Type</Form.Label>
            <Form.Control
              value={fabric_type}
              onChange={(e) => setFabricType(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="qrcode">
            <Form.Label>Qrcode</Form.Label>
            <Form.Control
              value={qrcode}
              onChange={(e) => setQrcode(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="image">
            <Form.Label>Product Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="images">
            <Form.Label>Product Images</Form.Label>
            <Form.Control
              type="file"
              multiple
              onChange={(e) => setImages(e.target.files)}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select category...</option>
              {categoryOptions.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="brand">
            <Form.Label>Brand</Form.Label>
            <Form.Control
              as="select"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            >
              <option value="">Select brand...</option>
              {brandOptions.map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="color">
            <Form.Label>Color</Form.Label>
            <Form.Control
              as="select"
              multiple // Add this attribute
              value={colors} // Pass the array of selected values
              onChange={(e) => handleChange(e, "color", setColors)} // Use the function to handle the change event
              required
            >
              <option value="">Select color...</option>
              {colorOptions.map((color) => (
                <option key={color._id} value={color._id}>
                  {color.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="size">
            <Form.Label>Size</Form.Label>
            <Form.Control
              as="select"
              multiple // Add this attribute
              value={sizes} // Pass the array of selected values
              onChange={(e) => handleChange(e, "size", setColors, setSizes)}
              required
            >
              <option value="">Select size...</option>
              {sizeOptions.map((size) => (
                <option key={size._id} value={size._id}>
                  {size.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="is_offer">
            <Form.Label>Is Offer</Form.Label>
            <Form.Select
              value={is_offer}
              onChange={(e) => setIsOffer(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="0">False</option>
              <option value="1">True</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="offer_type">
            <Form.Label>Offer Type</Form.Label>
            <Form.Select
              value={offer_type}
              onChange={(e) => setOfferType(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="offer_price">
            <Form.Label>Offer Price</Form.Label>
            <Form.Control
              type="number"
              value={offer_price}
              onChange={(e) => setOfferPrice(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="is_new">
            <Form.Label>Is New</Form.Label>
            <Form.Select
              value={is_new}
              onChange={(e) => setIsNew(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="0">False</option>
              <option value="1">True</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="is_new">
            <Form.Label>Is Featured</Form.Label>
            <Form.Select
              value={is_featured}
              onChange={(e) => setIsFeatured(e.target.value)}
            >
              <option value="">Select an option</option>
              <option value="0">False</option>
              <option value="1">True</option>
            </Form.Select>
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingUpdate} type="submit">
              Update
            </Button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}
