import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="m-5">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Updated at 2023-01-01</strong>
      </p>
      <p>
        AG Atelier (“we,” “our,” or “us”) is committed to protecting your
        privacy. This Privacy Policy explains how your personal information is
        collected, used, and disclosed by AG Atelier.
      </p>
      <p>
        This Privacy Policy applies to our website, and its associated
        subdomains (collectively, our “Service”) alongside our application, AG
        Atelier. By accessing or using our Service, you signify that you have
        read, understood, and agree to our collection, storage, use, and
        disclosure of your personal information as described in this Privacy
        Policy and our Terms of Service.
      </p>
      <h2>Definitions and key terms</h2>
      <p>
        To help explain things as clearly as possible in this Privacy Policy,
        every time any of these terms are referenced, are strictly defined as:
      </p>
      <ul>
        <li>
          <strong>Cookie:</strong> small amount of data generated by a website
          and saved by your web browser. It is used to identify your browser,
          provide analytics, remember information about you such as your
          language preference or login information.
        </li>
        <li>
          <strong>Company:</strong> when this policy mentions “Company,” “we,”
          “us,” or “our,” it refers to Hedge Technologies for Information
          Technology LLC, (Al Muhammadiyah Tower, Khobar, Eastern Province) that
          is responsible for your information under this Privacy Policy.
        </li>
        <li>
          <strong>Country:</strong> where AG Atelier or the owners/founders of
          AG Atelier are based, in this case is Saudi Arabia
        </li>
        <li>
          <strong>Customer:</strong> refers to the company, organization or
          person that signs up to use the AG Atelier Service to manage the
          relationships with your consumers or service users.
        </li>
        <li>
          <strong>Device:</strong> any internet connected device such as a
          phone, tablet, computer or any other device that can be used to visit
          AG Atelier and use the services.
        </li>
        <li>
          <strong>IP address:</strong> Every device connected to the Internet is
          assigned a number known as an Internet protocol (IP) address. These
          numbers are usually assigned in geographic blocks. An IP address can
          often be used to identify the location from which a device is
          connecting to the Internet.
        </li>
        <li>
          <strong>Personnel:</strong> refers to those individuals who are
          employed by AG Atelier or are under contract to perform a service on
          behalf of one of the parties.
        </li>
        <li>
          <strong>Personal Data:</strong> any information that directly,
          indirectly, or in connection with other information — including a
          personal identification number — allows for the identification or
          identifiabilit…
        </li>
      </ul>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>Don't hesitate to contact us if you have any questions.</p>
      <ul>
        <li>
          <strong>Via Email:</strong> info@agatelier.com
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
