import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";
import SideBar from "../components/SideBar";
import { Store } from "../Store";
import { getError, makeApiRequest } from "../utils";
import { Table } from "react-bootstrap";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, loading: false, reviews: action.payload };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "DELETE_REQUEST":
      return { ...state, loadingDelete: true, successDelete: false };
    case "DELETE_SUCCESS":
      return { ...state, loadingDelete: false, successDelete: true };
    case "DELETE_FAIL":
      return { ...state, loadingDelete: false };
    case "DELETE_RESET":
      return { ...state, loadingDelete: false, successDelete: false };
    default:
      return state;
  }
};
export default function ReviewsListScreen() {
  const [{ loading, error, reviews, loadingDelete, successDelete }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });
        const { data } = await makeApiRequest(`/review/all`, "get", null, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log(data);
        dispatch({ type: "FETCH_SUCCESS", payload: data.reviews });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: getError(err) });
      }
    };
    if (successDelete) {
      dispatch({ type: "DELETE_RESET" });
    } else {
      const data = fetchData();
      console.log(data);
    }
  }, [userInfo, successDelete]);
  const deleteHandler = async (review) => {
    if (window.confirm("Are YOU Sure to delete?")) {
      try {
        dispatch({ type: "DELETE_REQUEST" });
        const delorder = await makeApiRequest(
          `/review/${review._id}`,
          "delete",
          null,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        console.log(delorder);
        toast.success("Review deleted successfully");
        dispatch({ type: "DELETE_SUCCESS" });
      } catch (err) {
        toast.error(getError(err));
        dispatch({ type: "DELETE_FAIL" });
      }
    }
  };
  return (
    <Row className="minHieght">
      <Col xs={12} md={4} lg={4} xl={3}>
        <SideBar />
      </Col>
      <Col xs={12} sm={12} md={7} lg={8} xl={9}>
        <Helmet>
          <title>Reviews</title>
        </Helmet>
        <h1 className="mt-4">Reviews</h1>

        {loadingDelete && <LoadingBox />}
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <Table responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>USER ID</th>
                <th>PRODUCT ID</th>
                <th>COMMENT</th>
                <th>RATE</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {reviews.map((review) => (
                <tr key={review._id}>
                  <td>{review._id}</td>
                  <td>{review.customer_id} </td>
                  <td>{review.product_id}</td>
                  <td>{review.comment}</td>
                  <td>{review.rate}</td>
                  <td>
                    <Button
                      type="button"
                      variant="danger"
                      onClick={() => deleteHandler(review)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
}
