import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet-async";
import Form from "react-bootstrap/Form";
import { Store } from "../Store";
import Button from "react-bootstrap/esm/Button";
import Container from "react-bootstrap/esm/Container";
import LoadingBox from "../components/LoadingBox";
import ListGroup from "react-bootstrap/esm/ListGroup";
import { toast } from "react-toastify";
import { getError, makeApiRequest } from "../utils";
import axios from "axios";
import MessageBox from "../components/MessageBox";
import { useNavigate, useParams } from "react-router-dom";
const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "UPDATE_REQUEST":
      return { ...state, loadingUpdate: true };
    case "UPDATE_SUCCESS":
      return { ...state, loadingUpdate: false };
    case "UPDATE_FAIL":
      return { ...state, loadingUpdate: false };
    case "UPLOAD_REQUEST":
      return { ...state, loadingUpload: true, errorUpload: "" };
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        loadingUpload: false,
        errorUpload: "",
      };
    case "UPLOAD_FAIL":
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};

function CreateCategory() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: productId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: "",
    });

  const [name, setName] = useState("");
  const [order, setOrder] = useState("");
  const [has_children, setHasChildren] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: "FETCH_REQUEST" });

        dispatch({ type: "FETCH_SUCCESS" });
      } catch (err) {
        dispatch({
          type: "FETCH_FAIL",
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [productId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch({ type: "UPLOAD_REQUEST" });
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("order", order);
      formData.append("has_children", has_children);

      formData.append("category_image", image);
      formData.append("category_description", description);
      const { data } = await makeApiRequest("/category", "post", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      console.log(data);
      dispatch({ type: "UPLOAD_SUCCESS" });
      toast.success("Category created successfully");
      navigate("/admin/category");
    } catch (err) {
      dispatch({
        type: "UPLOAD_FAIL",
        payload: getError(err),
      });
    }
  };

  return (
    <Container className="small-container my-3">
      <Helmet>
        <title>New Category ${`${productId}`}</title>
      </Helmet>
      <h1>New Category {productId}</h1>

      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>name</Form.Label>
            <Form.Control onChange={(e) => setName(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="order">
            <Form.Label>Order </Form.Label>
            <Form.Control onChange={(e) => setOrder(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3" controlId="has_children">
            <Form.Label>Has Chlidren</Form.Label>
            <Form.Control
              onChange={(e) => setHasChildren(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="image">
            <Form.Label>Image File</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {loadingUpload && <LoadingBox></LoadingBox>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>
          <div className="mb-3">
            <Button disabled={loadingUpdate} type="submit">
              Add Category
            </Button>
            {loadingUpdate && <LoadingBox></LoadingBox>}
          </div>
        </Form>
      )}
    </Container>
  );
}

export default CreateCategory;
