import axios from "axios";
export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

// Use a function to handle the change event
export const handleChange = (e, type, setColors, setSizes) => {
  const options = e.target.options;
  const selectedValues = [];
  for (let i = 0; i < options.length; i++) {
    if (options[i].selected) {
      selectedValues.push(options[i].value);
    }
  }
  if (type === "color") {
    setColors(selectedValues);
  } else {
    setSizes(selectedValues);
  }
};
const axiosInstance = axios.create({
  baseURL: "https://ag-atelier-0d82a36df9ec.herokuapp.com/",
});

export function makeApiRequest(endpoint, method, data = null, headers = {}) {
  const config = {
    url: endpoint,
    method: method,
  };

  if (data && (method === "post" || method === "put" || method === "patch")) {
    config.data = data;
  }

  if (Object.keys(headers).length > 0) {
    config.headers = headers.headers;
    console.log(config);
  }

  return axiosInstance(config);
}
